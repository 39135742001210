/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

@import "app/theme/styles/spaces";
@import "app/theme/styles/base";
@import "app/theme/styles/theme";
@import "app/theme/styles/gradients";



.w-100 {
  width: 100% !important;
}

.card {
  border: 1px solid #d2d2d2;
  border-radius: 10px;
  padding: 10px 10px;
}

.float-right{
  float: right;
}

.text-white{
  color: #ffffff;
}


button.mat-button.mat-flat-button.mat-primary {
  color: #fff !important;
  background-color: #ec502d !important;
}

.logo {
  width: 183px !important;
}

