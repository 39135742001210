@import "variables";

/******* Base styles *******/
html{
  height: 100%;
}
body{
  height: 100%;
  margin:0; padding: 0;
  font-family: $font-family;
  font-size: 14px;
  line-height: 1.5;
}
*{
  margin: 0;
  padding: 0;
}
a{
  outline: none;
}
.h-100 {
  height: 100% !important;
}
.w-100{
  width: 100% !important;
}
.h-auto {
  height: auto !important;
}
.mw-100{
  max-width: 100%;
}
.fw-300{
  font-weight: 300;
}
.fw-400{
  font-weight: 400;
}
.fw-500{
  font-weight: 500;
}
.lh{
  line-height: 27px;
}
.d-block {
  display: block !important;
}
.theme-container{
  max-width: $theme-max-width;
  margin: 0 auto;
  width: 100%;
}
.light-block.mat-mdc-card{
  background: rgba(0,0,0,0.03) !important;
  box-shadow: none !important;
}
.transition{
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  transition: 0.3s;
}
.text-truncate{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden !important;
}
.text-center{
  text-align: center;
}
.text-right{
  text-align: right;
}
.divider{
  border-top: 1px solid rgba(204, 204, 204, 0.6);
}



.mat-button.mat-primary,
.mat-stroked-button.mat-primary {
  color: #ec502d !important;
}

.mat-button.mat-primary:hover,
.mat-flat-button.mat-primary:hover,
.mat-icon-button.mat-primary:hover{
  background-color: #fd6220 !important;
}

.mat-raised-button.mat-primary,
.mat-flat-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  color: #fff !important;
  background-color: #ec502d !important;
}


.mat-button,
.mat-flat-button {
  background-color: #ec502d !important;
  color: white !important;
}

.mat-icon-button.mat-primary {
  background-color: #ec502d !important;
  color: white !important;
}

.mat-flat-button[disabled],
.mat-raised-button[disabled],
.mat-icon-button[disabled] {
  background-color: #dcdcdc !important;
  color: #bebebe !important;
}

.mat-stroked-button[disabled] {
  color: #bebebe !important;
}


.mat-flat-button[disabled]:hover,
.mat-raised-button[disabled]:hover,
.mat-icon-button[disabled]:hover {
  background-color: #dcdcdc !important;
  color: #bebebe ;
}


.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #ec502d ;
}



.mat-mdc-icon-button .mat-icon.mat-icon-xs,
.mat-icon.mat-icon-xs{
  font-size: 14px !important;
  line-height: 14px !important;
  height: 14px !important;
  width: 14px !important;
  vertical-align: middle !important;
  overflow: unset !important;
}
.mat-mdc-icon-button .mat-icon.mat-icon-sm,
.mat-icon.mat-icon-sm,
.mat-nav-list .mat-mdc-list-item .mat-mdc-list-item-icon.mat-icon-sm{
  font-size: 18px !important;
  line-height: 18px !important;
  height: 18px !important;
  width: 18px !important;
  vertical-align: middle !important;
  overflow: unset !important;
}
.mat-mdc-icon-button .mat-icon.mat-icon-md,
.mat-icon.mat-icon-md,
.mat-nav-list .mat-mdc-list-item .mat-mdc-list-item-icon.mat-icon-sm{
  font-size: 24px !important;
  line-height: 24px !important;
  height: 24px !important;
  width: 24px !important;
  vertical-align: middle !important;
  overflow: unset !important;
}
.mat-mdc-icon-button .mat-icon.mat-icon-lg,
.mat-icon.mat-icon-lg{
  font-size: 36px !important;
  line-height: 36px !important;
  height: 36px !important;
  width: 36px !important;
  vertical-align: middle !important;
  overflow: unset !important;
}
.mat-mdc-icon-button .mat-icon.mat-icon-xlg,
.mat-icon.mat-icon-xlg{
  font-size: 48px !important;
  line-height: 48px !important;
  height: 48px !important;
  width: 48px !important;
  vertical-align: middle !important;
  overflow: unset !important;
}
.mat-mdc-button>.mat-icon.caret{
  width: 14px;
  margin: 0 !important;
}
.mat-step-text-label{
  text-transform: uppercase;
}
.mat-mdc-row, .mat-mdc-header-row{
  border-bottom-color: rgba(0,0,0,.12);
}

.pages-container .mat-drawer-content {
  overflow: hidden;
}

.mat-drawer-container{
  background-color: white !important;
}

.filter-sidenav .mat-drawer-inner-container{
  padding: 2px;
  box-sizing: border-box;
  .mat-expansion-panel{
    border-radius: 0 !important;
  }
}

.app-dropdown.mat-mdc-menu-panel{
  min-width: 60px !important;
}
.app-dropdown .mat-mdc-menu-item{
  min-height: 36px;
  line-height: 36px;
  font-size: 14px;
  .mdc-list-item__primary-text {
    display: flex;
    align-items: center;
  }
}
.account-icon{
  margin-top: -2px;
  margin-right: 2px;
}
.app-dropdown.account{
  .mat-mdc-menu-content{
    padding: 0;
    .user-info{
      padding: 16px;
      img{
        margin-right: 16px;
        border-radius: 3px;
      }
      .mat-icon{
        margin-top:-2px;
        color: #fbc02d;
      }
    }
  }
}
.categories-dropdown.mat-mdc-menu-panel{
  max-height: 350px;
}
.categories-dropdown .mat-mdc-menu-item{
  min-height: 24px;
  line-height: 24px;
  font-size: 14px;
}
.sub-category button{
  padding-left: 30px;
}
.sub-category .sub-category button{
  padding-left: 50px;
}

.d-block{
  display: block;
}

.info-bar{
  margin-top: 30px;
  .mat-mdc-card{
    height: 100px;
    .content{
      margin-left: 10px;
      p{
        font-weight: 500;
      }
      span{
        font-size: 13px;
      }
    }
  }
}


.logo{
  font-size: 28px;
  font-weight: 900;
  text-transform: uppercase;
  text-decoration: none;
  color: inherit;
}

/******* Responsive *******/
// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575px) {
  .top-toolbar.mat-toolbar-row{
    padding: 0;
    .top-menu{
      margin-right: 0;
      a{
        min-width: 56px;
      }
    }
    .sidenav-toggle{
      min-width: 52px;
    }
  }
  .search-form input[type=text]{
    width: 160px;
  }
  .search-form .categories{
    width: 140px;
  }

}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767px) {
  .top-toolbar.mat-toolbar-row{
    padding: 0;
    .top-menu{
      margin-right: 0;
    }
    .sidenav-toggle{
      min-width: 52px;
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991px) {
  .top-toolbar.mat-toolbar-row{
    padding: 0;
    .top-menu{
      margin-right: 0;
    }
    .sidenav-toggle{
      min-width: 52px;
    }
  }

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199px) {  }

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {  }

@media (min-width: 1380px) {
  .theme-container{
    max-width: 1400px;
  }
}

.sidenav .mat-drawer-inner-container {
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 0px none #ffffff;
  border-radius: 0px;
  &:hover{
    background: #cccccc;
  }
  &:active{
    background: #888888;
  }
}
::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
  border-radius: 0px;
  &:hover{
    background: #666666;
  }
  &:active{
    background: #333333;
  }
}
::-webkit-scrollbar-corner {
  background: transparent;
}


//----------ng-scrollbar---------------
ng-scrollbar {
  --scrollbar-size: 6px !important;
  --scrollbar-thumb-color: rgba(204,204,204,0.8) !important;
  --scrollbar-thumb-hover-color: #ccc !important;
  --scrollbar-border-radius: 4px !important;
  --scrollbar-hover-size: 8px !important;
  --scrollbar-padding: 2px !important;
}

// .menu-sidenav {
//   background-color: #000 !important;
//   color: #fff !important;
//   width: 270px !important;

//   .mat-tree {
//     background-color: transparent !important;
//     // background: #000 !important;
//     .child {
//       background-color: #000 !important;
//       color: #fff !important;
//       width: 100% !important;
//       text-align: left !important;
//       padding: 5px 0 5px 15px !important;
//     }
//     .child:hover {
//       background-color: #363636 !important;
//       color: white !important;
//       transition: 0.3s !important;
//     }
//   }
// }

// .menu-sidenav-content > *:not(.exclude) {
//   width: 90% !important;
// }

.menu-sidenav {
  background-color: #2a2a2a !important;
  color: #fff !important;
  width: 270px !important;

  .mat-tree {
    background-color: transparent !important;
    //background: #000 !important;
    .child {
       background-color: #2a2a2a !important;
      color: #fff !important;
      width: 100% !important;
      text-align: left !important;
      padding: 5px 0 5px 15px !important;
    }
    .child:hover {
       background-color: #ec502d !important;
      color: white !important;
      transition: 0.3s !important;
    }
    .parent {
       background-color: #2a2a2a !important;
      color: #fff !important;
      width: 100% !important;
      text-align: left !important;
      padding: 5px 0 5px 15px !important;
    }
    .parent:hover {
       background-color: #ec502d !important;
      color: white !important;
      transition: 0.3s !important;
    }
  }
}

// .menu-sidenav-content > *:not(.exclude) {
//   width: 90% !important;
// }
//------vantana modal-------------
.mat-dialog-container {
  box-shadow: 0 11px 15px -7px #0003,0 24px 38px 3px #00000024,0 9px 46px 8px #0000001f;
  background: #fff;
  color: #000000de;
  max-height: 80vw !important;
  overflow-y: auto !important;
}

//------------checkbox-----

.mat-checkbox.mat-accent {
  .mat-checkbox-frame {
    border-color: #0082c0 !important;
  }
  .mat-checkbox-background {
    background-color: #0082c0 !important;
  }
  .mat-checkbox-checkmark-path {
    stroke: #ffffff !important;
  }

}

.mat-select-panel-wrap{
  background-color: white;
}

/*Reduces mat form field´s height*/

mat-form-field.mat-form-field.mat-form-field-appearance-outline > div.mat-form-field-wrapper > div.mat-form-field-flex > div.mat-form-field-infix {
  padding: 0.4em 0px
}

mat-form-field.mat-form-field.mat-form-field-appearance-outline > div.mat-form-field-wrapper > div.mat-form-field-flex > div.mat-form-field-infix > span.mat-form-field-label-wrapper {
  top: -1.4em;
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  transform: translateY(-1.1em) scale(.75);
  width: 133.33333%;
}



.mat-checkbox.mat-accent {
  .mat-checkbox-frame {
    border-color: #ec502d !important;
  }
  .mat-checkbox-background {
    background-color: #ec502d !important;
  }
  .mat-checkbox-checkmark-path {
    stroke: #ffffff !important;
  }
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle{
  border-color: rgba(0,0,0,.70) ;
}

.mat-divider {
  border-top-color: rgba(186, 186, 186, 0.7);
}

.mat-radio-button.mat-accent .mat-radio-inner-circle{
  color:#ec502d;
  background-color:#ec502d ;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color:#ec502d;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: #b3b3b3;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #ec502d;
}
